import  React from 'react';
import { useState } from 'react';
import {NavLink, Link} from 'react-router-dom';

function MobileRendersubmenu({sideMenu, isOpen, onClose, setOpen}) {
 
  const onMyClose = ()=>{
    setOpen(!isOpen);
    onClose();
  }

  return (

        <div className="w-screen ">
          <ul className={`${isOpen? "bg-white rounded-lg mt-4 pt-1 max-h-100 w-[85%]": "max-h-0 invisible"} transition-all duration-200 ease-in-out overflow-hidden shadow-sm rounded-1`}>
            {sideMenu.map((submenu,index) => (

            <React.Fragment key= {index} >
            <li  className="flex flex-col pl-4 text-[0.8rem] font-normal items-left gap-x-3 cursor-pointer p-1 rounded-md mt-2 mx-2 hover:bg-slate-200 ">
                <NavLink to ={submenu.path} onClick={()=>onMyClose()} className ="text-[#3a3b45] selection:font-medium text-sm flex-1 duration-200 py-1 rounded-md">
                {submenu.title}
                </NavLink>
            </li>
            </React.Fragment>
          ))};
        </ul>
        </div>
  );
}

export default MobileRendersubmenu
import React from 'react'
import axios from 'axios'
import {useState, useEffect} from 'react'
import { IoMdSave } from "react-icons/io";
import { PencilSquareIcon ,ArchiveBoxXMarkIcon } from '@heroicons/react/24/solid'

function StoixeiaEtaireias() {
  {/* Ορίζω μεταβλητές βάσης */}
  const [data,setData] = useState([]);
  const [epwnhmia, setEpwnhmia] = useState('');
  const [drasthriothta,setDrasthriothta] = useState('');
  const [diakritikosTitlos, setDiakritikosTitlos] = useState('');
  const [polh,setPolh] = useState('');
  const [afm,setAfm] = useState('');
  const [dieuthinsi,setDieuthinsi] = useState('');
  const [arithmos,setArithmos] = useState('');
  const [tk,setTk] = useState('');
  const [doy,setDoy] = useState('');
  const [gemh,setGemh] = useState('');
  const [mhtrow,setMhtrow] = useState('');
  const [thlwfwno,setThlefwno] = useState('');
  const [kinhto,setKinhto] = useState('');
  const [email,setEmail] = useState('');
  const [logo,setLogo] = useState('');
  const [trapezes,setTrapezes] = useState('');
  const [parathrhseis, setParathrhseis] = useState('');
  const [installCode, setInstallCode] = useState('');
  const [taxisUser,setTaxisUser] = useState('');
  const [taxisPass, setTaxisPass] = useState('');
  const [mydataUser, setMydataUser] = useState('');
  const [mydataPass, setMydataPass] = useState('');
  const [disabled,setDisabled] = useState(true);

  {/* Κανω get για να λάβω τις actual μεταβλητές από τη βάση */}
  const getTableData = async () => {

    await axios.get('https://docon.gr/parametroi/stoixeia-etaireias/')
    .then(res=> setData(res.data))
    .catch(err=> console.log(err.response.data));
    
  }
  const onSubmitForm = ()=>{


  }
  const setmyDisabled = (e)=>{
    e.preventDefault();
    setDisabled(!disabled);
  }

  function handleChange(event) {
    setLogo(event.target.files[0])
    console.log(logo);
  }
  
{/* Ελέγχω για τυχόν αλλαγές στις μεταβλητές */}
useEffect(()=>{

  getTableData();
 
},[])

{/* Αρχικό div οθόνης */}
  return (
   

     <div className=" shadow-md overflow-x-hidden flex flex-col justify-items-center p-10 text-center justify-center overflow-y-hidden bg-white mt-5 mx-4 rounded-md">
      <div className="flex flex-col border-[#d1d3e2] border-b shadow-md rounded-md">
      <h1 className="lg:text-2xl text-[#3a3b45] font-semibold flex justify-center">Στοιχεία Εταιρείας</h1>
      <span className="flex text-center font-normal lg:text-1xl md:text-sm sm:text-sm justify-center mt-3 mb-3">Παράμετροι</span>
      </div>
      

     {/* Δημιουργία φόρμας */}

     <form onSubmit = {onSubmitForm}>
      <div className="flex-col mt-10 md:w-full lg:w-full">

        {/* Div επωνυμίας*/}    
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full">
          <label className=" flex justify-items-start justify-left text-left w-[40%]">
          <span className="flex justify-items-start text-red-500">* <span className=" ml-1 flex text-black text-[15px] font-normal ">Επωνυμία:</span></span>
          </label>
          <input type="text" value={epwnhmia} onChange={e=>setEpwnhmia(e.target.value)} placeholder="Η επωνυμία της επιχείρησης" className=" px-3 flex h-9 w-full text-sm mt-2 bg-white focus:outline-none focus:border-4 focus:border-[#70b7c5] focus:shadow-md border-2 border-[#d1d3e2] ml-3 outline-none rounded-md " required />
          </div>

          {/* Div δραστηριότητας*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className="flex justify-items-start justify-left text-left w-[40%]">
          <span className="flex justify-items-start text-red-500">* <span className=" ml-1 flex text-black text-[15px] font-normal ">Δραστηριότητα:</span></span>
          </label>
          <input type="text" value={drasthriothta} onChange={e=>setDrasthriothta(e.target.value)} placeholder="Η δραστηριότητα της επιχείρησης"className="px-3 flex h-9 w-full text-sm bg-white focus:border-4 border-2 border-[#d1d3e2] focus:outline-none focus:border-[#70b7c5] focus:shadow-md ml-3 outline-none rounded-md "required />
          </div>

           {/* Div διακριτικός τίτλος*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left md:w-[40%] lg:w-[40%] w-full">
          <span className="flex text-black text-[15px] font-normal py-2">Διακριτικός Τίτλος:</span>
          </label>
          <input type="text" value={diakritikosTitlos} onChange={e=>setDiakritikosTitlos(e.target.value)} placeholder="Ο διακριτικός τίτλος" className=" px-3 flex h-9 w-full text-sm  bg-white border-2 focus:border-4 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md " />
          </div>

           {/* Div Πόλη*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left  w-[40%]">
          <span className="flex justify-items-start text-red-500">* <span className=" ml-1 flex text-black text-[15px] font-normal ">Πόλη:</span></span>
          </label>
          <input type="text" value={polh} onChange={e=>setPolh(e.target.value)} placeholder="Η πόλη που δραστηριοποιείται η επιχείρηση" className=" px-3 flex h-9 w-full text-sm  bg-white border-2 focus:border-4 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md " required/>           
          </div>

           {/* Div ΑΦΜ*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left  w-[40%]">
          <span className="flex justify-items-start text-red-500">*
          <span className="flex text-black text-[15px] font-normal ml-1">ΑΦΜ:</span>
          </span>
          </label>
          <input type="text" value={afm} onChange={e=>setAfm(e.target.value)} placeholder="Το ΑΦΜ της επιχείρησης" className=" px-3 flex h-9 w-full text-sm  bg-white border-2 focus:outline-none focus:border-4 focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md " required/>
          </div>


           {/* Div Διεύθυνση*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className="justify-items-start justify-left text-left w-[39%]">
          <span className="flex justify-items-start text-red-500">*
          <span className="flex text-black text-[15px] font-normal ml-1">Διεύθυνση:</span>
          </span>
          </label>
            <div className="flex flex-row w-full">
            <input type="text" value={dieuthinsi} onChange={e=>setDieuthinsi(e.target.value)} placeholder="Διεύθυνση" className=" px-3 flex-auto h-9 w-[70%] text-sm bg-white border-2 focus:border-4 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md " required/>
            <input type="number" value={arithmos} onChange={e=>setArithmos(e.target.value)} placeholder="Αριθμός" onWheel={ event => event.currentTarget.blur() } className=" px-1 flex-auto h-9 md:w-[20%] lg:w-[20%] w-[25%] text-sm focus:border-4 bg-white border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" required/>
            <input type="number" value={tk} onChange={e=>setTk(e.target.value)} placeholder="ΤΚ." onWheel={ event => event.currentTarget.blur() } className=" px-1 flex-auto h-9 w-[20%] text-sm  bg-white  focus:border-4 border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" required/>
            </div>
          </div>


           {/* Div ΔΟΥ*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left  w-[40%]">
          <span className="flex justify-items-start text-red-500">*
          <span className="flex text-black text-[15px] font-normal ml-1">ΔΟΥ:</span>
          </span>
          </label>
          <input type="text" value={doy} onChange={e=>setDoy(e.target.value)} placeholder="Η ΔΟΥ που ανήκει η επιχείρηση" className=" px-3 flex h-9 w-full text-sm  bg-white border-2  focus:border-4 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md " required/>
          </div>

           {/* Div ΓΕΜΗ*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left  w-[40%]">
          <span className="flex text-black text-[15px] font-normal ml-1">ΓΕΜΗ:</span>
          </label>
          <input type="text" value={gemh} onChange={e=>setGemh(e.target.value)} placeholder="Ο αριθμός ΓΕΜΗ της επιχείρησης" className=" px-3 flex h-9 w-full text-sm  bg-white border-2 focus:border-4 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md "/>
          </div>


           {/* Div Αριθμός Μητρώου*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left  md:w-[40%] lg:w-[40%] w-full">
          <span className="flex text-black text-[15px] font-normal ml-1">Αριθμός μητρώου:</span>
          </label>
          <input type="text" value={mhtrow} onChange={e=>setMhtrow(e.target.value)} placeholder="Ο αριθμός μητρώου της επιχείρησης" className=" px-3 flex h-9 w-full text-sm  bg-white border-2 focus:border-4 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md "/>
          </div>

           {/* Div Τηλέφωνο*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left  w-[40%]">
          <span className="flex justify-items-start text-red-500">* <span className=" ml-1 flex text-black text-[15px] font-normal ">Τηλέφωνο:</span></span>
          </label>
          <input type="number" value={thlwfwno} onChange={e=>setThlefwno(e.target.value)} placeholder="Ο αριθμός τηλεφώνου της επιχείρησης" className=" px-3 flex h-9 w-full text-sm  bg-white  focus:border-4 border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" required/>
          </div>

           {/* Div Κινητό*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left  w-[40%]">
          <span className="flex justify-items-start text-red-500">* <span className=" ml-1 flex text-black text-[15px] font-normal ">Κινητό:</span></span>
          </label>
          <input type="number" value={kinhto} onChange={e=>setKinhto(e.target.value)} placeholder="Το κινητό τηλέφωνο της επιχείρησης" className=" px-3 flex h-9 w-full text-sm  bg-white focus:border-4  border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" required/>
          </div>

           {/* Div Email*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left w-[40%]">
          <span className="flex justify-items-start text-red-500">*
          <span className="flex text-black text-[15px] font-normal ml-1">Email:</span>
          </span>
          </label>
          <input type="text" value={email} onChange={e=>setEmail(e.target.value)} placeholder="Το email της επιχείρησης" className=" px-3 flex h-9 w-full text-sm  bg-white border-2 focus:border-4 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md " />
          </div>

           {/* Div Logo*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left w-[40%]">
          <span className="flex text-black text-[15px] font-normal py-2">Logo:</span>
          </label>
          <input type="file"  onChange={handleChange} className=" px-3 flex h-9 w-full text-sm bg-white focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 focus:border-4 outline-none rounded-md " />
          </div>


           {/* Div Τραπεζικοί λογαριασμοί*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left md:w-[40%] lg:w-[40%] w-full">
          <span className="flex text-black text-[15px] font-normal py-2">Τραπεζικοί λογαριασμοί:</span>
          </label>
          <input type="text" value={trapezes} onChange={e=>setTrapezes(e.target.value)} placeholder="πχ Eurobank GR5896625896, Πειραιώς GR45224454447" className=" px-3 flex h-9 w-full text-sm focus:border-4 bg-white border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md " />
          </div>


           {/* Div Σταθερές παρατηρήσεις στα Παραστατικά*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left md:w-[40%] lg:w-[40%] w-full">
          <span className="flex text-black text-[15px] font-normal py-2">Σταθερές παρατηρήσεις στα Παραστατικά:</span>
          </label>
          <input type="text" value={parathrhseis} onChange={e=>setParathrhseis(e.target.value)} placeholder="πχ Επιστροφές δεκτές μόνο με αποστολή σχετικού δελτίου-απόδειξης" className=" px-3 flex h-9 w-full text-sm focus:border-4 bg-white border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md " />
          </div>


           {/* Div Κωδικός Εγκατάστασης*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className="flex flex-row justify-items-start justify-left text-left">
          <span className="flex justify-items-start text-red-500">*
          <span className="flex text-black text-[15px] font-normal ml-1">Κωδικός Εγκατάστασης:</span>
          </span>
          <input type="number" disabled={disabled} value={installCode} onChange={e=>setInstallCode(e.target.value)} onWheel={ event => event.currentTarget.blur() } className={` ${disabled ? "bg-slate-200": "bg-white [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"} "my-auto px-3 flex h-9 w-[15%] text-sm focus:border-4  border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none" `} required/>
          <button className="flex ml-4 my-auto text-[#1a97af] hover:text-[#70b7c57a]" onClick={e=>setmyDisabled(e)}>
            <PencilSquareIcon className="size-8 "/>
          </button>
          </label>
          </div>

           {/* Div Καταχώρησης*/} 
          <div className="flex flex-row justify-between mt-10 w-full">
          <div className="flex">
          <p className="flex font-thin text-sm">Τα πεδία με ( <span className="text-red-400">*</span>  ) είναι υποχρεωτικά</p>
          </div>
          <div className="flex">
          <button className="flex flex-row float-right justify-end bg-[#186c2a] hover:bg-[#186c2a77] text-white font-semibold py-2 px-3 border rounded-md" onClick={onSubmitForm}>
          <IoMdSave className="size-4 my-auto"/>
          <span className="text-sm">Καταχώρηση</span>
          </button>
          </div>
          </div>
          </div>
          </form>
          
</div>


     
  )
}

export default StoixeiaEtaireias
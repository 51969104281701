import React from 'react'

function PerigrafhInfo({flag}) {

    if(!flag) return null;
  return (
    <div className="bg-white absolute z-10 min-w-30 px-4 py-2 shadow-sm float-end lg:ml-[18%] lg:mt-[2%] md:ml-[18%] md:mt-[6%] mt-[8%] ml-[25%] xs:ml-[5%] xs:mt-[15%] border-2 border-slate-300">
        <p className="text-xs">Μέγιστος αριθμός χαρακτήρων 50.</p>
    </div>
  )
}

export default PerigrafhInfo